* {
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  background: #ebebeb;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

body::-webkit-scrollbar-thumb {
  background-color: #267dff;
}

.ant-notification-notice {
  color: white;
  padding: 12px 24px !important;
  &.ant-notification-notice-warning {
    background-color: #faad14;
  }
  &.ant-notification-notice-error {
    background-color: #de3d3d;
  }
  &.ant-notification-notice-success {
    background-color: #26ae33;
  }
  .ant-notification-notice-icon {
    display: none;
  }
  .ant-notification-notice-message {
    color: white !important;
    margin-bottom: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: -0.24px;
    margin-bottom: 0px !important;
    margin-inline-start: 0px !important;
  }
  .ant-notification-notice-close {
    font-size: 16px !important;
    color: white !important;
    top: 14px !important;
  }
  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ant-form {
  .ant-form-item {
    margin-bottom: 32px;
    .ant-form-item-label {
      color: #1c1c1c;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 24px;
    }
  }
}

.ant-btn-primary.btn-dark {
  border-radius: 8px;
  background: #1c1c1c;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  height: 52px;
  &:hover {
    background: #1c1c1c !important;
  }
}

.page-title {
  color: #1c1c1c;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
  @media all and (max-width: 768px) {
    margin-bottom: 24px;
  }
}

.box {
  border-radius: 8px;
  background: #fff;
  padding: 32px;
  @media all and (max-width: 768px) {
    padding: 16px;
  }
}

.actions-footer-modal {
  display: flex;
  gap: 8px;
  button.ant-btn {
    height: 40px;
  }
}

.loading-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tabs {
  .ant-tabs-nav {
    margin-bottom: 20px;
    &::before {
      border-bottom: none;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding: 12px 16px;
          @media all and (max-width: 768px) {
            padding: 8px;
          }
          .ant-tabs-tab-btn {
            color: #a3adbd;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media all and (max-width: 768px) {
              font-size: 14px;
            }
          }
          &.ant-tabs-tab-active,
          &:hover {
            border-bottom: none;
            border-radius: 8px;
            background: #1c1c1c;
            .ant-tabs-tab-btn {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 24px;
  }
}

.ant-modal-header {
  .ant-modal-title {
    font-size: 24px;
  }
}

.ant-modal .ant-modal-content {
  padding: 20px 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.status {
  &.ACTIVE {
    color: #009b2f;
  }
  &.DEACTIVE {
    color: #ff0000;
  }
}

.btn-warning {
  background: #fd6900 !important;
  &:hover {
    background: #fd6900bf !important;
  }
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.gap-16 {
  gap: 16px;
  @media screen and (max-width: 768px) {
    gap: 8px;
  }
}

.status-transaction {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 999px;
  background: #e6f5eb;
  width: 139px;
  margin: 0 auto;
  &.success {
    color: #009b2f;
  }
  &.pending {
    background: #fff1e6;
    color: #fd6900;
  }
  &.rejected {
    background: #ff0b37;
    color: white;
  }
  .anticon {
    font-size: 18px;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ant-layout-sider {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    img {
      margin-top: 20px;
      width: 143px;
    }
  }
  .ant-menu {
    flex-grow: 1;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #267dff;
    }
    &::-webkit-scrollbar-track {
      background-color: #e7e7e7;
      border: 1px solid #cacaca;
    }
    .ant-menu-item {
      height: 52px;
      background: transparent !important;
      padding: 0px 20px !important;
      margin-inline: 20px;
      width: calc(100% - 40px);
      &:hover,
      &.ant-menu-item-selected {
        border-radius: 8px;
        background: #f6f7f9 !important;
      }
    }

    .ant-menu-item,
    .ant-menu-submenu {
      .ant-menu-item-icon {
        font-size: 24px;
        color: #768091;
      }
      .icon-normal {
        display: block;
        margin-right: 20px;
      }
      .icon-active {
        display: none;
        margin-right: 20px;
      }
      .ant-menu-title-content {
        color: #768091;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .ant-menu-submenu-arrow {
        color: #768091;
      }
      &.ant-menu-item-selected,
      &:hover {
        .icon-normal {
          display: none;
        }
        .icon-active {
          display: block;
        }
        .ant-menu-item-icon {
          color: #267dff;
        }
        .ant-menu-submenu-arrow {
          color: #267dff;
        }
        .ant-menu-title-content {
          color: #267dff;
        }
        ul li {
          .ant-menu-title-content {
            color: #267dff;
          }
        }
      }
      &.ant-menu-submenu-open {
        .icon-normal {
          display: none;
        }
        .icon-active {
          display: block;
        }
        .ant-menu-item-icon,
        .ant-menu-title-content,
        .ant-menu-submenu-arrow {
          color: #267dff;
        }
      }
      ul li {
        color: #62554c !important;
        .ant-menu-title-content {
          color: #62554c !important;
        }
        &.ant-menu-item-selected,
        &:hover {
          .ant-menu-title-content {
            color: #267dff !important;
          }
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        height: 52px;
        padding: 0px 20px !important;
        margin-inline: 20px;
        width: calc(100% - 40px);
        &:hover {
          border-radius: 8px;
          background: #f6f7f9 !important;
          .ant-menu-title-content,
          .ant-menu-submenu-arrow {
            color: #1c1c1c;
          }
        }
      }
      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {
          border-radius: 8px;
          background: #f6f7f9 !important;
          .ant-menu-title-content,
          .ant-menu-submenu-arrow {
            color: #1c1c1c;
          }
        }
      }
    }

    ul.ant-menu-sub {
      overflow-y: hidden;
      margin: 0px 20px 0px 48px;
      border-left: 1px solid #d6dae1;
      background: transparent !important;
      .ant-menu-item-only-child {
        background: transparent !important;
        padding-right: 0 !important;
        width: calc(100% - 20px);
        &:hover,
        &.ant-menu-item-selected {
          background: #f6f7f9 !important;
        }
      }
    }
  }
  .logout {
    border-top: 1px solid #ebedf1;
    height: 80px;
    padding: 0px 16px;
    cursor: pointer;
    .logout-item {
      display: flex;
      align-items: center;
      color: #1c1c1c;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 16px 0px;
      img {
        height: 24px;
        width: 24px;
        margin: 0px 20px;
      }
    }
  }
  @media all and (max-width: 1199px) {
    display: none;
  }
}

.ant-drawer {
  height: -webkit-fill-available;
  .ant-layout-sider {
    height: -webkit-fill-available !important;
  }
}

.ant-drawer-body {
  padding: 0px !important;
}

.ant-table-wrapper .ant-table-cell {
  @media all and (max-width: 768px) {
    padding: 8px !important;
  }
}
